import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../../context/context";
import {  FaCreditCard } from 'react-icons/fa'
import { FcIdea } from 'react-icons/fc'
import { FiPhoneCall, FiMapPin, FiClock } from 'react-icons/fi'
import { GoCalendar } from 'react-icons/go'
import { ButtonContent } from "../boton/ButtonContent";


function FooterOne() {
  const { rpdata } = useContext(GlobalDataContext);

  const menu = [
    {
      name: "Home",
      link: "/",
    },
    {
      name: "About",
      link: "/about",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Gallery",
      link: "/gallery",
    },
    {
      name: "Contact",
      link: "/contact",
    },
  ];

  // agregar la pestaña de reviews al array de dbMenu

  const el = {
    name: `Reviews`,
    link: `/reviews`,
    child: false,
  }

  if (rpdata?.reviews?.activo && rpdata?.reviews?.isHomeOnly === false) {
    const num = menu.length - 1
    menu.splice(num, 0, el)
  }
  //  fin de agregar pestaña de reviews

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <div className="bgBloqueGradiente py-10">
        <div className="w-4/5 mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="w-full md:w-1/2 md:text-start text-center pb-5">
            <h2 className="text-black">{rpdata?.dbSlogan?.[4]?.slogan}</h2>
          </div>
          <ButtonContent />
        </div>
      </div>
      <div className="w-full flex justify-center bg-gray-400 bg-opacity-5 p-3 py-14 bg-footer text-white">
        <div className="w-[90%] mx-auto">
          <div className="flex flex-wrap">
            <div className="self-center pt-10 w-full md:w-[22%]">
              <img src={rpdata?.dbPrincipal?.logo} className="w-[100%] object-cover" alt="Not Found" />
            </div>
            <div className="pt-10 px-0 md:px-5 w-full md:w-[28%]">
              <h5>About</h5>
              <p className="pb-4">
                {
                  rpdata?.simpleWidgets?.[3]?.activo ?
                    rpdata?.dbAbout?.[1].text
                    :
                    rpdata?.dbAbout?.[1].text.substring(0, 230)
                }
              </p>
              <ul className="flex">
                {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                  return (
                    <li key={index} className='mr-7 px-3 rounded-md bgBloque py-1'>
                      <a href={item.url} target="_blank" rel='noopener noreferrer' >
                        <i
                          className={`fab fa-${item.icon}`}
                          aria-hidden="true"
                        />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>


            {/* Navigation */}
            {
              rpdata?.simpleWidgets?.[3]?.activo ?
                null :
                <div className="pt-10 w-full md:w-[12%]">
                  <h5>Navigation</h5>
                  <ul className="pl-2">
                    {
                      menu.map((item, index) => {
                        return (
                          <li className="py-2" onClick={goToTop} key={index}>
                            <Link to={item.link} className="flex">
                              <FcIdea className="self-center" />
                              <span className="pl-2">{item.name}</span>
                            </Link>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
            }

            <div className="pt-10 w-full md:w-[16%]">
              <h5>Services</h5>
              <ul className="pl-2">
                {rpdata?.dbServices?.slice(0, 6).map((item, index) => (

                  rpdata?.simpleWidgets?.[3]?.activo ?
                    <li className="py-2" onClick={goToTop} key={index}>
                      <a href={`tel:+1${rpdata?.dbPrincipal?.phones?.[0]?.phone}`} className="flex">
                        <FcIdea className="self-center" />
                        <span className="pl-2">{item.name}</span>
                      </a>
                    </li>
                    :
                    <li className="py-2" onClick={goToTop} key={index}>
                      <Link to="/services" className="flex">
                        <FcIdea className="self-center" />
                        <span className="pl-2">{item.name}</span>
                      </Link>
                    </li>
                ))}
              </ul>
            </div>


            {/* Contact */}
            <div className="pt-10 w-full md:w-[22%]">
              <h5>Contact</h5>
              <ul className="pl-2">
                {rpdata?.dbPrincipal?.location?.slice(0, 1).map((item, index) => {
                  return (
                    <Link to="/contact" key={index}>
                      <li className="py-2 flex items-center">
                        <FiMapPin fontSize={25} color={'#FCD219'} />
                        <span className="pl-3">{item.address}</span>
                      </li>
                    </Link>
                  );
                })}
                {rpdata?.dbPrincipal?.phones?.map((item, index) => {
                  return (
                    <a href={`tel:+1${item.phone}`} key={index}>
                      <li className="py-2 flex items-center">
                        <FiPhoneCall fontSize={25} color={'#FCD219'} />
                        <span className="pl-3">{item.phone} {item.name}</span>
                      </li>
                    </a>
                  );
                })}
                {rpdata?.dbPrincipal?.workdays.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index} >
                      <GoCalendar fontSize={25} color={'#FCD219'} />
                      <span className="pl-3">{item.day}</span>
                    </li>
                  );
                })}
                {rpdata?.dbPrincipal?.workHours.map((item, index) => {
                  return (
                    <li className="py-2 flex items-center" key={index}>
                      <FiClock fontSize={25} color={'#FCD219'} />
                      <span className="pl-3">{item.hour}</span>
                    </li>
                  );
                })}
                {

                  <li className="py-2 flex flex-col">
                    <span className="flex items-center">
                      <FaCreditCard fontSize={25} color={'#FCD219'} />
                      <span className="pl-3 capitalize">{rpdata?.dbPrincipal?.paymentMethod}</span>
                    </span>
                    {
                      rpdata?.tiposPago?.[0]?.activo ?
                        <img src={rpdata?.tiposPago?.[0]?.img} width={'300'} alt='no found' />
                        : null
                    }
                  </li>
                }

              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full  text-center text-white  py-4 bg-[#3B5997]">
        <p>
          ©2022, Copy Right By {rpdata?.dbPrincipal?.name}. All Rights Reserved
        </p>

      </div>
    </>
  );
}

export default FooterOne;
